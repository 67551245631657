import React from "react";
import Hero from "../../../../Components/SOLUTION/Other-solution/crp-tests/Hero";
import Content from "../../../../Components/SOLUTION/Other-solution/crp-tests/Content";
import Faq1 from "../../../Faq/Faq1";
import CardCarousel from "../../../../Components/HoverCard/CardCarousel";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Welcome to My Care Labs | Comprehensive Health Solutions</title>
        <meta
          name="description"
          content="Discover comprehensive health solutions at My Care Labs. From infectious diseases to wellness and toxicology, we're here for your well-being."
        />
        <meta
          name="keywords"
          content="health solutions, My Care Labs, infectious diseases, wellness, toxicology, book appointments, check test results, home test kits"
        />
        <meta name="author" content="My Care Labs" />
      </Helmet>
      <Hero />
      <Content />
      {/* <Faq1/> */}
      {/* <section class="sm:mt-6 lg:mt-8 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

<div
    class="my-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex gap-3 lg:flex-justify lg:flex flex-col lg:flex-row">
    <div class="sm:text-center lg:text-left">
        <h1 class="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">CRP </span>
            <span class="block text-indigo-600 xl:inline">Tests</span>
        </h1>
        <p
            class="mt-3 text-base text-gray-500 text-justify sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
The C-reactive protein (CRP) blood test is a valuable tool used in healthcare to assess inflammation levels in the body. This test measures the amount of CRP, a protein produced by the liver, in the blood. Elevated CRP levels are indicative of inflammation, which can arise due to various underlying causes. Understanding the significance of this test, its uses, interpretation, and implications is crucial for maintaining optimal health and managing various medical conditions.        </p>
        
        <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
                <a href="/covid-test-locations"
                    class="w-full flex no-underline items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 md:py-4 md:text-lg md:px-10">
                    Book Appointment
                </a>
            </div>
           
        </div>
    
    </div>

   
    <div class="lg:inset-y-0 lg:right-0 lg:w-1/2 my-4">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://i.ibb.co/znmP6yc/5.png" alt=""/>
    </div>
   
</div>

</section> */}

      <div class="container flex items-center bg-blue-400 my-5 rounded-2xl">
        <div class="px-2 container mt-3 mb-6 sm:mt-24">
          <div class="text-center">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-200 sm:text-5xl md:text-6xl font-title">
              <span class="block text-left">
                What is C-Reactive Protein (CRP)?
              </span>
            </h1>
            <div>
              <h1 class="text-3xl text-white font-bold mb-4 text-left">
                CRP is an acute-phase protein produced by the liver in response
                to inflammation, infection, or tissue damage. Its levels rise
                rapidly in the bloodstream in response to these conditions and
                fall once the inflammation subsides.
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section class="font-sans text-black container">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full ">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/4033148/pexels-photo-4033148.jpeg?auto=compress&cs=tinysrgb&w=600"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight  text-left text-4xl font-bold">
                1. Types of CRP Tests
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>High-Sensitivity CRP (HS-CRP):</strong>{" "}
                    High-Sensitivity CRP (HS-CRP)
                  </p>
                </li>

                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong> Standard CRP Test :</strong> Standard CRP Test:
                    Measures higher levels of CRP and is used to detect and
                    monitor acute inflammation.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container my-5">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl text-left  font-bold">
                2. Uses of the CRP Blood Test
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Inflammatory Conditions : </strong>CRP levels serve
                    as a marker for a range of inflammatory conditions,
                    including arthritis, lupus, and inflammatory bowel disease.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Infections : </strong>Elevated CRP levels can
                    indicate bacterial or viral infections, aiding in diagnosis
                    and monitoring treatment effectiveness.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Cardiovascular Risk Assessment : </strong> hs-CRP
                    levels help assess the risk of heart disease and stroke.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Post-Surgery Monitoring :</strong> CRP levels can be
                    monitored after surgery to check for postoperative
                    complications like infections.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/207601/pexels-photo-207601.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/208518/pexels-photo-208518.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl text-left  font-bold">
                3. Interpreting CRP Levels
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Normal Levels: </strong>Normal Levels: Typically,
                    CRP levels in healthy individuals are less than 10
                    milligrams per liter (mg/L). However, specific laboratories
                    might have varying reference ranges.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Elevated Levels : </strong> Levels above the normal
                    range indicate inflammation. Higher CRP levels often
                    correlate with the severity of inflammation or infection.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container my-5">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl  text-left font-bold">
                4. Factors Influencing CRP Levels
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Inflammation : </strong>Any condition causing
                    inflammation, such as infections, injuries, or chronic
                    diseases like arthritis or inflammatory bowel disease, can
                    elevate CRP levels.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Medications : </strong>Certain medications, like
                    statins or nonsteroidal anti-inflammatory drugs (NSAIDs),
                    can affect CRP levels.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Lifestyle Factors : </strong>Smoking, obesity, and a
                    sedentary lifestyle can contribute to increased CRP levels.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/305565/pexels-photo-305565.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/2324837/pexels-photo-2324837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl  text-left font-bold">
                5. Importance of the CRP Test in Health
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Early Detection : </strong>Elevated CRP levels can
                    indicate inflammation before symptoms manifest, allowing for
                    early intervention and treatment.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Risk Assessment :</strong> Monitoring CRP levels
                    aids in assessing the risk of cardiovascular diseases and
                    guiding preventive measures.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Treatment Monitoring :</strong> CRP tests help
                    evaluate the effectiveness of treatments in managing
                    inflammatory conditions or infections.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container my-5">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl  text-left font-bold">
                6. Preparation and Procedure
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong>Preparation :</strong> No specific preparation is
                    required. The test involves a simple blood draw.
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong>Procedure : </strong>Procedure: A healthcare
                    professional collects a blood sample from a vein in the arm
                    using a needle. The sample is then sent to a laboratory for
                    analysis.
                  </p>
                </li>

                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong></strong>The CRP blood test serves as a valuable
                    tool in healthcare, providing insights into inflammation
                    levels and aiding in the diagnosis, monitoring, and
                    management of various medical conditions. Understanding the
                    significance of CRP levels and their implications helps
                    individuals and healthcare providers make informed decisions
                    to promote overall health and well-being.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/2280549/pexels-photo-2280549.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container my-5">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/356040/pexels-photo-356040.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
          <div class="p-6 bg-grey">
            <div class="leading-relaxed">
              <h2 class="leading-tight text-4xl  text-left font-bold">
                Role of My Care Labs in c reactive protein blood test
              </h2>
              <ul class="space-y-4 text-justify">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong></strong>My Care Labs, a leading healthcare
                    facility, plays a crucial role in facilitating CRP blood
                    tests, providing individuals and healthcare professionals
                    with invaluable insights into inflammatory processes within
                    the body.{" "}
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong></strong>The CRP blood test, offered by My Care
                    Labs, involves a simple and routine blood draw. The
                    collected sample is then processed and analyzed to measure
                    the concentration of CRP present in the bloodstream. This
                    protein is produced by the liver in response to
                    inflammation, and its levels rise rapidly in the presence of
                    acute inflammatory conditions.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="font-sans text-black container my-5">
        <div class="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
          <div class="p-6 bg-grey   ">
            <div class="leading-relaxed">
              <ul class="space-y-4 text-justify ">
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg  ">
                    <strong></strong>Understanding CRP levels is essential in
                    diagnosing and monitoring various health conditions. My Care
                    Labs assists in the interpretation of CRP test results,
                    offering comprehensive analysis and explanations to patients
                    and healthcare providers. Elevated CRP levels can be
                    indicative of acute conditions such as infections, tissue
                    injury, or chronic inflammatory diseases like rheumatoid
                    arthritis or inflammatory bowel disease.{" "}
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>

                  <p class="text-lg">
                    <strong></strong> Moreover, CRP levels are also recognized
                    as a potential marker for cardiovascular health. High
                    sensitivity CRP (hs-CRP) tests, offered by My Care Labs,
                    measure lower levels of CRP, and are specifically used in
                    assessing cardiovascular risk. Elevated hs-CRP levels have
                    been associated with an increased risk of cardiovascular
                    events, such as heart attacks and strokes. My Care Labs
                    assists in conducting hs-CRP tests, aiding in risk
                    assessment and preventive measures against cardiovascular
                    diseases.{" "}
                  </p>
                </li>
                <li class="flex items-start">
                  <svg
                    class="h-6 w-6 text-blue-500 mr-2 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                    />
                  </svg>
                  <p class="text-lg">
                    <strong></strong>The role of My Care Labs extends beyond
                    conducting CRP blood tests. They ensure accurate and timely
                    delivery of test results, providing a comprehensive report
                    detailing CRP levels and their potential implications.
                    Additionally, My Care Labs collaborates with healthcare
                    professionals to guide patients on necessary lifestyle
                    modifications, treatment options, and further diagnostic
                    tests based on CRP test results.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
            <div class="h-full">
              <article class="h-full">
                <div class="h-full">
                  <img
                    class="h-full object-cover rounded-2xl"
                    src="https://images.pexels.com/photos/127873/pexels-photo-127873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="733"
                    height="412"
                    alt='""'
                    typeof="foaf:Image"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <Faq1/> */}
      <CardCarousel />

      <div class="container sm:mx-auto overflow-hidden flex items-center bg-blue-900 rounded-full my-5">
        <div class="px-5 md:px-4 container mt-12 mb-16 sm:mt-20">
          <div class="text-center">
            <h1 class="text-xl font-extrabold tracking-tight text-gray-200 sm:text-4xl md:text-5xl font-title">
              <span class="block">
                My Care Labs is a certified BBB accredited business member.
              </span>
            </h1>
            <p class="text-sm sm:text-2xl text-gray-300">
              My Care Labs offers quick, hassle-free, and accurate COVID-19
              RT-PCR testing. With same day results or within 24 hours, we have
              testing on-site at our laboratory and at pop-ups throughout
              California. My Care Labs takes your health seriously. We are a
              CLIA-certified laboratory, part of the California Covid Task
              Force, partner with CDPH, and report directly to CDC.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
