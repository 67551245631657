import React from 'react';
import CleverPushWidget from './CleverPushWidget';

const GoogleStories = () => {
  return (
    <>
      <br />
      <br />
      <br />
   <CleverPushWidget/>
    </>
  );
};

export default GoogleStories;
